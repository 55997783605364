import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { LoaderState } from '../../loader/loader';

@Injectable({
    providedIn: 'root'
})
export class LoaderService {
    
    loadingSub: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    loadingMap: Map<string, boolean> = new Map<string, boolean>();

    constructor() { }

    setLoading(loading: boolean, url: string): void {
        if (!url) {
            throw new Error('Nenhuma url foi passada em LoadingService.setLoading')
        }
        if (loading == true) {
            this.loadingMap.set(url, loading);
            this.loadingSub.next(true);
        } else if (loading == false && this.loadingMap.has(url)) {
            this.loadingMap.delete(url);
        }
        if (this.loadingMap.size === 0) {
            this.loadingSub.next(false);
        }
    }
}
