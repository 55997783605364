<div [class]="tableResponsive() == true ? 'table-responsive' : ''">
    <div class="loader" [ngClass]="{ active: loading }">
        <p>Carregando...</p>
    </div>
    <table
        class="display table nowrap"
        datatable
        [dtTrigger]="dtTrigger"
        [dtOptions]="options"
        id="table"
        [ngClass]="classes"
    >
         <thead>
             <tr>
                 <th *ngIf="ischecked">
                     <input type="checkbox" [(ngModel)]="selectedAll" (change)="changeCheckAll()" [checked]="checkAll()">
                 </th>
                 <th *ngFor="let campo of campos" [class]="campo.ordenar ? 'sorting text-center ' + this.classOrdenacao : 'text-center'" (click)="ordenarColuna()">
                     {{ campo.nome }}
                 </th>
             </tr>
         </thead>
         <tbody>
             <tr *ngFor="let dado of dados; let i = index"  [ngClass]="{'sombra-linha': corLinhaSelecionada && checks[i]}"
                >
                 <td  *ngIf="ischecked">
                     <input type="checkbox" [(ngModel)]="checks[i]" (change)="changeCheck();" name="check">
                 </td>
                 <td 
                    *ngFor="let campo of campos" class="text-center" id="tdConteudo"
                    [ngStyle]="(dado[campo.campo].background) 
                        ? {'background': dado[campo.campo].background, 'color': 'black'} 
                        : {'background':''}"
                        >
                        
                     <a
                         *ngIf="
                             dado[campo.campo].isLink &&
                             !dado[campo.campo].isDownload &&
                             !dado[campo.campo].isIconText
                         "
                         (click)="
                             openOptions.emit({
                                id: dado[campo.campo].label,
                                data: dado
                            })
                        "
                        >
                        {{ dado[campo.campo].label || '' }}
                    </a>

                    <span
                        *ngIf="
                            dado[campo.campo].isLink &&
                            dado[campo.campo].isIconText
                        " 
                    >
                        {{ dado[campo.campo].label || '' }}
                        <a  
                            (click)="
                                openOptions.emit({
                                id: dado[campo.campo].icon,
                                data: dado
                            })"
                        >
                            <i class="{{'feather icon-' + dado[campo.campo].icon}}"></i>
                        </a>
                    </span>

                    <a
                        *ngIf="
                            dado[campo.campo].isLink && dado[campo.campo].isIcon && !dado[campo.campo].isIconText
                        "
                        (click)="
                            openOptions.emit({
                                id: dado[campo.campo].icon,
                                data: dado
                            })
                        "
                    >
                        <i
                            class="{{
                                'feather icon-' + dado[campo.campo].icon
                            }}"
                        ></i>
                    </a>

                    <a
                        *ngIf="
                            dado[campo.campo].isIcon &&
                            dado[campo.campo].isDownload
                        "
                        href="{{ dado[campo.campo].downloadUrl }}"
                        download="{{ dado[campo.campo].label }}"
                        target="_blank"
                        class="td-icon"
                    >
                        <i
                            class="{{
                                'feather icon-' + dado[campo.campo].icon
                            }}"
                        ></i>
                    </a>

                    <i
                        *ngIf="
                            dado[campo.campo].isIcon &&
                            !dado[campo.campo].isDownload &&
                            !dado[campo.campo].isLink 
                        "
                        class="td-icon {{
                            'feather icon-' + dado[campo.campo].icon
                        }}"
                        [attr.title]="disabledButton"
                    ></i>
                    <img
                        *ngIf="
                            dado[campo.campo].isImg &&
                            !dado[campo.campo].isDownload &&
                            !dado[campo.campo].isLink 
                        "
                        src="../../../assets/images/home.svg"
                    >

                    <span
                         *ngIf="
                             !dado[campo.campo].isLink &&
                             !dado[campo.campo].isDownload &&
                             !dado[campo.campo].isIcon &&
                             dado[campo.campo].isStatus"

                        class="circle-status {{ dado[campo.campo].status }}"
                    >
                    </span>
                    <span
                        *ngIf="
                            !dado[campo.campo].isLink &&
                            !dado[campo.campo].isDownload &&
                            !dado[campo.campo].isIcon &&
                            !dado[campo.campo].isStatus
                            !dado[campo.campo].isImg
                        "
                    >
                        {{
                            dado[campo.campo].label === '0'
                                ? '-'
                                : dado[campo.campo].label || '-'
                        }}
                    </span>
                </td>
            </tr>
        </tbody>
        <tfoot>
            <tr *ngIf="calcColumn === 'all'">
                <th>
                    Total:
                </th>
                <ng-container
                    *ngFor="let campo of campos | slice: 1; let i = index"
                >
                    <th>
                        <span
                            *ngIf="
                                allTotal[campo.nome] !== NaN &&
                                allTotal[campo.nome].mask === 'number'
                            "
                        >
                            {{
                                allTotal[campo.nome] !== NaN
                                    ? allTotal[campo.nome].label
                                    : ''
                            }}</span
                        >
                        <span
                            *ngIf="
                                allTotal[campo.nome] !== NaN &&
                                allTotal[campo.nome].mask === 'money'
                            "
                        >
                            {{
                                allTotal[campo.nome] !== NaN
                                    ? (allTotal[campo.nome].label
                                      | currency: 'BRL')
                                    : ''
                            }}
                        </span>
                    </th>
                </ng-container>
            </tr>
            <tr *ngIf="calcColumn && calcColumn !== 'all'">
                <th [attr.colspan]="calcColumn.position">
                    Total:
                </th>
                <th *ngIf="calcColumn.mascara === 'real'">
                    {{ totalCalc | currency: 'BRL' }}
                </th>
                <th *ngIf="calcColumn.mascara === 'number'">{{ totalCalc }}</th>
            </tr>
        </tfoot>
    </table>
</div>
