import {
    Component,
    Input,
    ElementRef,
    OnInit,
    ChangeDetectorRef,
    ChangeDetectionStrategy,
    DoCheck,
    Output,
    EventEmitter,
    ViewChild,
    AfterViewInit,
    SimpleChanges,
    OnChanges
} from '@angular/core';

import { DataTableOptions } from 'src/app/shared/tabela/tabela-options';
import { TabelaCampo } from 'src/app/shared/tabela/tabela-campo';
import { isSet } from 'src/app/shared/is/is-set';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'src/app/directives/datatable/datatable.directive';
// import { DataTableDirective } from 'angular-datatables';

@Component({
    selector: 'app-tabela',
    templateUrl: './tabela.component.html',
    styleUrls: ['./tabela.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TabelaComponent implements OnInit, DoCheck, OnChanges {
    @ViewChild(DataTableDirective, {static: false})
    dtElement: DataTableDirective;
    dtTrigger: Subject<any> = new Subject();

    @Input()
    striped: boolean;

    @Input()
    hover: boolean = true;

    @Input()
    calcColumn: any;

    @Input('footer')
    footer: boolean;

    @Input()
    campos: TabelaCampo[] = [];

    @Input()
    get dados(): any { return this._dados };
    set dados(dados: any) {
        this._dados = dados;
        if (this._dados) {
            this.validarDados();
            this.selectedAll = false;
        }
    }
    _dados: any;
    @Input() disabledButton: string;

    @Output() openOptions = new EventEmitter();

    @Input() classes: String[] = [];
    allTotal = [];
    loading: boolean = false;
    options: any;
    dadosPrev: any;
    @Input() ischecked = false;
    @Input() buttons = true;
    totalCalc = 0;
    title: String;
    names: any;
    selectedAll = false;
    semDados: boolean = false;
    @Input() checks = [];
    @Input() ordernar = false;
    dadosOrdenados: any[] = [];
    classOrdenacao: string = "";
    @Input() corLinhaSelecionada: boolean = false; 
    @Output() checkChange = new EventEmitter();


    constructor(private el: ElementRef, private cd: ChangeDetectorRef) {
        this.title = 'Teste';
        this.names = [{ name: 'Teste', selected: false }];
    }

    ngOnInit(): void {
        if (isSet(this.footer)) this.footer = true;
        this.options = {...DataTableOptions};
        if (this.buttons == false) {
            this.options.buttons = [];
        };

        this.dadosPrev = this.dados;
        this.gerarClasses();
        this.ordenarColuna();

        if(this.dados !== undefined) {
            this.dados.forEach(dados => {
                this.checks.push(false);
            });
        }

        if (this.calcColumn && typeof this.calcColumn === 'object') {
            this.dados.forEach(dado => {
                const value = dado[this.calcColumn.campo].label
                    .split(',')[0]
                    .replace('.', '');
                this.totalCalc += Number(value);
            });

            this.campos.forEach(campo => {
                if (campo.nome === this.calcColumn.nome) {
                    this.calcColumn['position'] = this.campos.indexOf(campo);
                }
            });
        }

        if (this.calcColumn && typeof this.calcColumn === 'object') {
            this.dados.forEach(dado => {
                const value = dado[this.calcColumn.campo].label
                    .split(',')[0]
                    .replace('.', '');
                this.totalCalc += Number(value);
            });

            this.campos.forEach(campo => {
                if (campo.nome === this.calcColumn.nome) {
                    this.calcColumn['position'] = this.campos.indexOf(campo);
                }
            });
        }

        if (this.calcColumn === 'all') {
            this.campos.forEach(campo => {
                this.dados.forEach(dado => {
                    const value = dado[campo.campo].label
                        .split(',')[0]
                        .replace('.', '');
                    if (Number(value) !== NaN) {
                        if (this.allTotal[campo.nome]) {
                            this.allTotal[campo.nome]['label'] += Number(value);
                        } else {
                            this.allTotal[campo.nome] = {
                                label: Number(value),
                                mask: dado[campo.campo].mask
                            };
                        }
                    }
                });
            });
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.dados.currentValue && changes.dados.firstChange == false) {
            this.dtTrigger.next(true);
        }
    }

    changeCheckAll() {
        for(let i = 0; i < this.checks.length; i++) {
            this.checks[i] = this.selectedAll;
        }
        this.checkChange.emit(this.checks);
    }

    changeCheck() {
        this.checkChange.emit(this.checks);
    }

    checkAll(): boolean {
        if (this.checks.every(item => item === true)) {
            this.selectedAll = true;
        } else {
            this.selectedAll = false;
        }

        return this.selectedAll;
    }

    selectAll() {
        for (var i = 0; i < this.names.length; i++) {
            this.names[i].selected = this.selectedAll;
        }
    }
    checkIfAllSelected() {
        this.selectedAll = this.names.every(function(item: any) {
            return item.selected == true;
        });
    }

    ngDoCheck(): void {
        this.cd.markForCheck();
    }

    validarDados() {
        (this._dados.length == 0 || this._dados == null || this._dados == undefined) ? this.semDados = true : this.semDados = false;
    }

    private gerarClasses(): void {
        if (isSet(this.striped)) this.classes.push('table-striped');
        if (isSet(this.hover)) this.classes.push('table-hover');
    }

    ordenarColuna() {
        if(this.ordernar == true) {
            this.options.ordering = true; 
            this.options.order = [[0, 'asc']]
            this.options.columnDefs[0].orderable = true;
            this.options.columnDefs.push( 
                { 
                    targets: '_all',
                    orderable: false
                }
            )
        } else {
            this.options.ordering = false;
        }
    }

    tableResponsive(): boolean {
        const table = this.el.nativeElement.querySelector('#table');
        const thElements = table.querySelectorAll('th');
        return table && thElements.length === 0 ? false : true;
    }
}
