import {
    Component,
    OnInit,
    ElementRef,
    ViewChild
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Event, NavigationEnd, Router } from '@angular/router';

import { AuthService } from '../../core/auth/auth.service';

import { PlatformDetectorService } from '../../core/platform-detector/platform-detector.service';
import { ValidatorCPF } from 'src/app/shared/validators/custom-validators';
import { SweetAlertService } from 'src/app/services/sweet-alert/sweet-alert.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { User } from 'src/app/shared/user/user';
import Swal from 'sweetalert2';

@Component({
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    loadingMessage: any;
    alerta = {
        show: true
    };

    @ViewChild('userNameInput') userNameInput: ElementRef<HTMLInputElement>;

    characters: string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    randomState: string = '';
    randomNonce: string = '';
    urlIntegracao: string = '';

    constructor(
        private sweetAlertService: SweetAlertService,
        private loaderService: LoaderService,
        private formBuilder: FormBuilder,
        private authService: AuthService,
        private router: Router,
        private platformDetectorService: PlatformDetectorService /*,
    private loginService: LoginService*/
    ) {}

    ngOnInit(): void {
        this.loginForm = this.formBuilder.group({
            cpf: [
                '',
                [
                    Validators.required,
                    ValidatorCPF,
                    Validators.minLength(11)
                ]
            ],
            senha: [
                '',
                [
                    Validators.required,
                    Validators.minLength(10),
                    Validators.maxLength(10)
                ]
            ]
        });


    }

    login(event: any): void {
        const cpf = this.loginForm.get('cpf').value;
        const senha = this.loginForm.get('senha').value;

        // this.loaderService.exibir();
        this.authService.authenticate(cpf, senha).subscribe(
            res => {
                const user = res.body as User;
                Swal.fire({
                    title: 'Autenticado com sucesso',
                    text: 'Aguarde, checando autorização',
                    showConfirmButton: false,
                    imageHeight: 100,
                    imageUrl: './assets/images/loader/loader.svg',
                    imageAlt: 'Loader'
                })
                this.router.events.subscribe((event: Event) => {
                    if(event instanceof NavigationEnd) {
                        Swal.close();
                    }
                })

                if (user.token === null) {
                    setTimeout(() => {
                        this.sweetAlertService.api({
                            codigo: 'A000',
                            mensagem: 'Não está autorizado o uso desta aplicação.',
                            solucao: 'Favor avaliar com o administrador as autorizações de uso.'
                        });

                        this.loginForm.reset();
                    }, 3000);
                } else {
                    setTimeout(() => {
                        this.router.navigate(['']);
                    }, 2000);
                }
            },
            err => {
                this.sweetAlertService.api({
                    codigo: 'E004',
                    mensagem: 'Autenticação inválida.',
                    solucao: 'Favor verificar seus dados de acesso.'
                });

                this.loginForm.reset();
            }
        );
    }

    validarCPF(): void {
        if (
            this.loginForm.controls.cpf.errors &&
            this.loginForm.controls.cpf.errors.notValid &&
            !this.loginForm.controls.cpf.errors.minlength
        ) {
            setTimeout(() => {
                this.sweetAlertService.api({
                    codigo: 'E005',
                    mensagem: 'C.P.F. inválido',
                    solucao: 'Informe um C.P.F. válido para prosseguir.'
                });
            }, 500);
        }
    }
}
