import { Component, OnInit, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { IntegracaoService } from 'src/app/services/integracao/integracao.service';

@Component({
  selector: 'app-acesso',
  templateUrl: './acesso.component.html',
  styleUrls: ['./acesso.component.scss']
})
export class AcessoComponent implements OnInit {

  characters: string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  randomState: string = '';
  randomNonce: string = '';
  code: any;
  state: any;
  urlIntegracao: string;

  constructor(
    private router: Router,
    private integracaoService: IntegracaoService
  ) { 

  }

  ngOnInit(): void {
    let prod = 'https%3A%2F%2Fsfera%2Esefaz%2Eto%2Egov%2Ebr';
    // let prod = 'https%3A%2F%2Flocalhost%3A4200%2Fintegracao';
    let nonce = (Math.random() + 1).toString(36).substring(7);
    let state = (Math.random() + 1).toString(36).substring(7);
    this.urlIntegracao = "https://sso.acesso.gov.br/authorize?response_type=code&client_id=dfe.sefaz.to.gov.br&scope=openid+email+phone+profile+govbr_empresa+govbr_confiabilidades&redirect_uri="+prod+"&nonce="+nonce+"&state="+state;
  }

}
